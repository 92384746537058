import { getType } from 'typesafe-actions'
import { TsaHubAction } from '../actions'
import {
  closeEtlAction,
  downloadTaxCodeMapAction,
  getAvailableColumnFiltersAction,
  getCategoryOptionsActions,
  getEntityOptionsActions,
  getInputProcessTemplatesActions,
  getEngagementK1FileStatusesActions,
  getK1StatusActions,
  getK1ByIdAction,
  getK1sByFileIdAction,
  getPivotDataAction,
  getPivotOptionsActions,
  getReceivingPartnersAction,
  getWorksheetOptionsAction,
  resetEtlScreensAction,
  setColumnFiltersAction,
  setDataMapAction,
  setEtlScreenLoadedAction,
  setExcludeMappedDataAction,
  setTaxCodeDataAction,
  setFileMappingValues,
  setValidationAndStepAction,
  setFileIdAction,
  updateK1FileStatusAction,  
  exportK1FileDataAction,
  setStructureTemplateAction,
  getStructureTemplateAction,
} from '../actions/inputProcessActions'
import {
  ClientEntity,
  EtlScreenMap,
  InputProcessTemplateMap,
  LoadStatus,
  Option,
  TaxCode,
  EtlScreen,
  EntityMap,
  K1FileStatusMap,
  K1Header,
  StructureWorksheet,
  StructureTemplate,
} from '../clientModels'
import {
  CloseActions,
  StructureMap,
  DataMap,
} from '../components/configureImport/interface'
import {
  ColumnFilters,
  Partner,
  K1FileStatus,
  EntityMapping,
} from '../services/api/apiModels'
import { ValidationInfo } from '../components/configureImport/helpers'

export const defaultTemplateSelection = {
  templateId: 0,
  newTemplateName: '',
}

export const defaultStructureMap: StructureMap = {
  format: {
    ...defaultTemplateSelection,
    cellToBeginImport: '',
    exclude: '',
    fileFormat: '',
    fileToInput: '1', // this property is useless
    templateId: 0,
    worksheetToImport: '',
  },
  pivotConfig: {
    columns: {},
    rows: {},
  },
}

export const defaultDataMap: DataMap = {
  ...defaultTemplateSelection,
  entityMap: {},
  taxCodeMap: {},
}

export const defaultStructureTemplate: StructureTemplate = {
  formatSetup: {
    startingCell: '',
    excludeRows: '',
    fileFormat: '',
    etlType: '1',
    worksheetName: '',
  },
  pivotSetup: {
    columns: {},
    rows: {},
  },
}

const defaultEtlScreenMap: EtlScreenMap<LoadStatus> = {
  categories: LoadStatus.notLoaded,
  define: LoadStatus.notLoaded,
  entities: LoadStatus.notLoaded,
  format: LoadStatus.notLoaded,
  initial: LoadStatus.notLoaded,
  pivot: LoadStatus.notLoaded,
  review: LoadStatus.notLoaded,
  scan: LoadStatus.notLoaded,
  structure: LoadStatus.notLoaded,
  taxCodes: LoadStatus.notLoaded,
}

export interface InputProcessState {
  availableColumnFilters: ColumnFilters
  categoryOptions: TaxCode[]
  closeInputProcess: number
  currentFileId: number
  dataMap: DataMap
  downloadingTaxCodeMap: boolean
  entityOptions: ClientEntity[]
  entityMappings: EntityMapping[]
  etlScreenLoaded: EtlScreenMap<LoadStatus>
  excludeMappedData: boolean
  k1FileStatuses: K1FileStatusMap
  k1s: K1Header[]
  loadingCategoryOptions: boolean
  loadingEntityOptions: boolean
  loadingPivotOptions: boolean
  loadingTemplates: boolean
  loadingWorksheets: boolean  
  pivotColumnOptions: Option[]
  pivotData: string[][]
  pivotRowOptions: Option[]
  processingWorksheet: boolean
  receivingPartners: Partner[]
  rowOptions: Option[]
  rowsToRetrieve: number
  step: EtlScreen
  structureMap: StructureMap
  structureTemplate: StructureWorksheet | null
  submittingUnassignedEntities: boolean
  taxCodeData: string[][]
  taxCodeFilters: ColumnFilters
  templates: InputProcessTemplateMap | null
  worksheetOptions: {
    options: Option[]
    fileId?: number
  }
  validation: EntityMap<ValidationInfo>
}

const initialState: InputProcessState = {
  availableColumnFilters: {},
  categoryOptions: [],
  closeInputProcess: CloseActions.None,
  // currentFileId is used for detecting when file changes
  // see useConfigureK1Storage.ts
  currentFileId: 0,
  dataMap: defaultDataMap,
  downloadingTaxCodeMap: false,
  entityOptions: [],
  entityMappings: [],
  etlScreenLoaded: defaultEtlScreenMap,
  excludeMappedData: false,
  k1FileStatuses: {},
  k1s: [],
  loadingCategoryOptions: false,
  loadingEntityOptions: false,
  loadingPivotOptions: false,
  loadingTemplates: false,
  loadingWorksheets: false,  
  pivotColumnOptions: [],
  pivotData: [],
  pivotRowOptions: [],
  processingWorksheet: false,
  receivingPartners: [],
  rowOptions: [],
  rowsToRetrieve: 100,
  structureMap: defaultStructureMap,
  structureTemplate: null,
  submittingUnassignedEntities: false,
  step: 'initial',
  taxCodeData: [],
  taxCodeFilters: {},
  templates: null,
  worksheetOptions: {
    options: [],
  },
  validation: {},
}

export function inputProcessReducer(
  state: InputProcessState = initialState,
  action: TsaHubAction
): InputProcessState {
  switch (action.type) {
    case getType(closeEtlAction): {
      return {
        ...initialState,
        categoryOptions: state.categoryOptions,
        k1FileStatuses: state.k1FileStatuses,
        k1s: state.k1s,
        templates: state.templates,
      }
    }

    case getType(exportK1FileDataAction.request):
    case getType(downloadTaxCodeMapAction.request): {
      return {
        ...state,
        downloadingTaxCodeMap: true,
      }
    }

    case getType(exportK1FileDataAction.success):
    case getType(exportK1FileDataAction.failure):
    case getType(downloadTaxCodeMapAction.success):
    case getType(downloadTaxCodeMapAction.failure): {
      return {
        ...state,
        downloadingTaxCodeMap: false,
      }
    }

    case getType(getAvailableColumnFiltersAction.request):
    case getType(getAvailableColumnFiltersAction.failure): {
      return {
        ...state,
        availableColumnFilters: {},
      }
    }

    case getType(getAvailableColumnFiltersAction.success): {
      return {
        ...state,
        availableColumnFilters: action.payload,
      }
    }

    case getType(getPivotDataAction.success): {
      return {
        ...state,
        pivotData: action.payload,
      }
    }

    case getType(getWorksheetOptionsAction.request): {
      return {
        ...state,
        loadingWorksheets: true,
      }
    }

    case getType(getWorksheetOptionsAction.failure): {
      return {
        ...state,
        loadingWorksheets: false,
      }
    }

    case getType(getWorksheetOptionsAction.success): {
      return {
        ...state,
        worksheetOptions: action.payload,
        loadingWorksheets: false,
      }
    }

    case getType(getCategoryOptionsActions.request): {
      return {
        ...state,
        loadingCategoryOptions: true,
      }
    }

    case getType(getCategoryOptionsActions.success): {
      return {
        ...state,
        categoryOptions: action.payload.categoryOptions,
        loadingCategoryOptions: false,
      }
    }

    case getType(getCategoryOptionsActions.failure): {
      return {
        ...state,
        loadingCategoryOptions: false,
      }
    }

    case getType(getEngagementK1FileStatusesActions.success): {
      const k1FileStatuses = action.payload
      const k1FileStatusMap = k1FileStatuses.reduce(
        (map: K1FileStatusMap, obj: K1FileStatus) => {
          map[obj.fileId] = obj
          return map
        },
        {}
      )

      return {
        ...state,
        k1FileStatuses: k1FileStatusMap,
      }
    }

    case getType(getK1StatusActions.success): {
      const k1FileStatus = action.payload
      var k1FileStatusMap = { ...state.k1FileStatuses }
      k1FileStatusMap[k1FileStatus.fileId] = k1FileStatus
      return {
        ...state,
        k1FileStatuses: k1FileStatusMap,
      }
    }

    case getType(getEntityOptionsActions.request): {
      return {
        ...state,
        loadingEntityOptions: true,
      }
    }

    case getType(getEntityOptionsActions.success): {
      return {
        ...state,
        entityOptions: action.payload.entityOptions,
        loadingEntityOptions: false,
      }
    }

    case getType(getEntityOptionsActions.failure): {
      return {
        ...state,
        loadingEntityOptions: false,
      }
    }

    case getType(getInputProcessTemplatesActions.request): {
      return { ...state, loadingTemplates: true }
    }

    case getType(getInputProcessTemplatesActions.success): {
      return {
        ...state,
        loadingTemplates: false,
        templates: action.payload.templates,
      }
    }

    case getType(getK1ByIdAction.success): {
      return { ...state, k1s: [action.payload] }
    }

    case getType(getK1sByFileIdAction.success): {
      return { ...state, k1s: action.payload }
    }

    case getType(getPivotOptionsActions.request): {
      return { ...state, loadingPivotOptions: true }
    }

    case getType(getPivotOptionsActions.success): {
      return {
        ...state,
        loadingPivotOptions: false,
        pivotColumnOptions: action.payload.pivotColumnOptions,
        pivotRowOptions: action.payload.pivotRowOptions,
        rowOptions: action.payload.rowOptions,
      }
    }

    case getType(getPivotOptionsActions.failure): {
      return { ...state, loadingPivotOptions: false }
    }

    case getType(getReceivingPartnersAction.request): {
      return {
        ...state,
        receivingPartners: [],
      }
    }

    case getType(getReceivingPartnersAction.success): {
      return {
        ...state,
        receivingPartners: action.payload,
      }
    }

    case getType(resetEtlScreensAction): {
      return {
        ...state,
        etlScreenLoaded: defaultEtlScreenMap,
      }
    }

    case getType(setDataMapAction): {
      return {
        ...state,
        dataMap: action.payload,
      }
    }

    case getType(setExcludeMappedDataAction): {
      return {
        ...state,
        excludeMappedData: action.payload,
      }
    }

    case getType(setFileMappingValues): {
      return {
        ...state,
        structureMap: action.payload,
      }
    }

    case getType(setFileIdAction): {
      // currentFileId is used for detecting when file changes
      // see useConfigureK1Storage.ts
      return {
        ...state,
        currentFileId: action.payload,
      }
    }

    case getType(setColumnFiltersAction): {
      return {
        ...state,
        taxCodeFilters: action.payload,
      }
    }

    case getType(getStructureTemplateAction.success):
    case getType(setStructureTemplateAction): {
      return {
        ...state,
        structureTemplate: action.payload,
      }
    }

    case getType(setTaxCodeDataAction.success): {
      return {
        ...state,
        taxCodeData: action.payload,
      }
    }

    case getType(setValidationAndStepAction): {
      return {
        ...state,
        step: action.payload.step,
        validation: action.payload.validation,
      }
    }

    case getType(setEtlScreenLoadedAction): {
      const newState = { ...state.etlScreenLoaded }
      newState[action.payload.screen] = action.payload.status

      return {
        ...state,
        etlScreenLoaded: newState,
      }
    }

    case getType(updateK1FileStatusAction.success): {
      const { k1FileStatuses } = state
      const k1FileStatus = action.payload
      return {
        ...state,
        k1FileStatuses: {
          ...k1FileStatuses,
          [k1FileStatus.fileId]: k1FileStatus,
        },
      }
    }

    default:
      return state
  }
}
