import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import moment from 'moment'
import React from 'react'
import { ensureNumber, ensureString } from '../../guards'
import Icon from '../icon/icon'
import { Column } from '../layout'
import { ConfirmDeleteFileDialog } from '../modals'
import { VersionIcon } from './versionIcon'
import { File, EtlFileProgress } from '../../services/api/apiModels'
import { K1FileStatus } from '../../clientModels'
import {
  EtlFileStatus as K1Status,
  FileStatus,
  UserPermissions,
} from '../../enums'
import { containsFileTag } from './fileSorts'
import { FileEventApi } from '../dataFileManagement/dataFileManagement'
import { useEngagement } from '../../hooks/useEngagement'
import { usePermissionManager } from '../../hooks'
import PermissionContent from '../internal/permissionContent'
import { DisplayStatus } from './displayStatus'

export interface FileListRowExternalProps {
  added: moment.Moment
  alt?: boolean
  baseClassName?: string
  by: string
  disabled: boolean
  etlProgress: EtlFileProgress | null
  file: File
  files: File[]
  handlers: FileEventApi
  k1FileStatus: K1FileStatus
  selected: boolean
  status?: string
}

export const FileListRow = ({
  added,
  alt,
  baseClassName,
  by,
  disabled,
  etlProgress,
  file,
  files,
  handlers,
  k1FileStatus,
  selected,
  status,
}: FileListRowExternalProps) => {
  const engagement = useEngagement()
  let isPrivilege = containsFileTag(file.tags, 'subject-to-privilege')
  let is7216 = containsFileTag(file.tags, 'subject-to-7216')
  const { hasPermission } = usePermissionManager()
  const canEditK1Documents = hasPermission(UserPermissions.K1DocumentsCanEdit)

  const handleChange = (
    tag: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (handlers.onClickFileCheckbox && e.currentTarget.dataset.id) {
      const id = ensureNumber(e.currentTarget.dataset.id)
      handlers.onClickFileCheckbox(id, tag)
      if (tag === 'subject-to-7216') {
        is7216 = true
      }
      if (tag === 'subject-to-privilege') {
        isPrivilege = true
      }
    }
  }

  const addedDatePhrase = (): string => {
    const now = moment()
    return moment.duration(now.diff(added)).asDays() > 2
      ? added.format('MM/DD/YYYY LT')
      : added.fromNow()
  }

  const [showConfirmDelete, displayDeleteConfirmation] = React.useState(false)

  const subjectTo7216Id = `${file.id}_input_subject_to_7216`
  const subjectTo7216LabelId = `${file.id}_label_subject_to_7216`
  const subjectToPrivilegeId = `${file.id}_input_subject_to_privilege`
  const subjectToPrivilegeLabelId = `${file.id}_label_subject_to_privilege`

  const fileType = file.name.split('.').pop()
  const showConfigure = !!(
    canEditK1Documents &&
    fileType &&
    ['xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xls', 'pdf'].includes(
      fileType.toLowerCase()
    )
  )
  const showVersionIcon = canEditK1Documents && !!file.versionId
  const noDelete: string[] = [
    FileStatus.Uploading,
    K1Status.InProgressContinue,
    K1Status.SubmissionInProgress,
    K1Status.Processing,
  ]
  const showDelete = (s: string | undefined) => {
    return s ? !noDelete.includes(s) : true
  }
  // TODO: This will be used for any file that has this extension before we allow them to start ETL.
  // const fileTypeWarning = !!(
  //   enableConfigure &&
  //   fileType &&
  //   ['xlsb', 'xls'].includes(fileType.toLowerCase())
  // )

  const handleShowError = () => {
    if (handlers.onClickShowError) {
      handlers.onClickShowError(file.id)
    }
  }

  return (
    <div className={classNames('file-list-row', { alt: alt }, baseClassName)}>
      <Column
        className='file-list-column-name'
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
      >
        <input
          type='checkBox'
          defaultChecked={selected}
          onChange={() =>
            handlers.onClickSelectRow && handlers.onClickSelectRow(file.id)
          }
        />
        <span data-tip={file.name}>{file.name}</span>
      </Column>
      <Column
        className='file-list-column-by'
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
      >
        {by && (
          <div className='file-list-column-by-name' data-tip={by}>
            {by}
          </div>
        )}
      </Column>
      <Column
        className='file-list-column-date'
        lg={1}
        xl={1}
        md={1}
        xs={1}
        sm={1}
      >
        <div className='file-list-column-by-date' data-tip={addedDatePhrase()}>
          {addedDatePhrase()}
        </div>
      </Column>
      <Column
        className='file-list-column-uploaded'
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
      >
        <DisplayStatus
          etlProgress={etlProgress}
          k1FileStatus={k1FileStatus}
          status={status}
          statusMessage={file.statusMessage}
          onClickShowError={handleShowError}
        />
      </Column>
      <Column
        className='file-list-column-buttons'
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
      >
        {handlers.onClickDelete &&
          showDelete(status) &&
          showDelete(k1FileStatus?.status) && (
            <Icon
              icon='delete'
              active={!disabled}
              tooltip='Remove file'
              onClick={() => !disabled && displayDeleteConfirmation(true)}
            />
          )}
        {handlers.onClickUpdate && (
          <Icon
            icon='upload'
            active={!disabled}
            tooltip='Replace file'
            onClick={() =>
              !disabled &&
              handlers.onClickUpdate &&
              handlers.onClickUpdate(file.id)
            }
          />
        )}
        {handlers.onClickDownload && (
          <Icon
            icon='download'
            active={true}
            tooltip='Download file'
            onClick={() =>
              handlers.onClickDownload && handlers.onClickDownload(file.id)
            }
          />
        )}
        {showVersionIcon && (
          <VersionIcon
            files={files}
            parentId={file.parentId}
            active={true}
            onClickDownload={handlers.onClickDownload}
          />
        )}
        {showConfirmDelete && (
          <ConfirmDeleteFileDialog
            title='This will permanently delete selected file(s) from your K1 documents list.'
            info=''
            primaryButtonText='Yes Delete Files'
            secondaryButtonText='No Keep Files'
            onClickPrimary={() =>
              handlers.onClickDelete && handlers.onClickDelete(file.id)
            }
            onClickSecondary={() => displayDeleteConfirmation(false)}
            onClose={() => displayDeleteConfirmation(false)}
          />
        )}
      </Column>
    </div>
  )
}

export default FileListRow
