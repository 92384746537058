import {
  Aborter,
  AnonymousCredential,
  BlobURL,
  BlockBlobURL,
  StorageURL,
  uploadBrowserDataToBlockBlob,
  BlobUploadCommonResponse,
} from '@azure/storage-blob'
import { File as TsaFile } from '../../clientModels'
import { buildApiUrl } from '.'
import * as http from '../http'

export function azureUploadFile(file: TsaFile, contents: Blob, sasUri: string) {
  const anonymousCredential = new AnonymousCredential()
  const pipeline = StorageURL.newPipeline(anonymousCredential)
  const blobUrl = new BlobURL(sasUri, pipeline)
  const blockBlobURL = BlockBlobURL.fromBlobURL(blobUrl)

  return uploadBrowserDataToBlockBlob(Aborter.none, contents, blockBlobURL, {
    progress: ev => {
      // console.log(file.name + ': ' + ((ev.loadedBytes / contents.size) * 100) + '%')
    },
  })
}

export const apiUploadBlob = (
  fileContents: Blob,
  sasUri: string,
): Promise<BlobUploadCommonResponse> => {
  return azureUploadFile({} as TsaFile, fileContents, sasUri)
}
