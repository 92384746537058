import { createStyles, Theme } from '@rsmus/react-ui'
import * as React from 'react'
import { createUseStyles } from 'react-jss'
import { FaSearch } from 'react-icons/fa'
import RenderFindClient from './RenderFindClient'
import { ClientSearch } from '../../clientModels'
import { Modal } from '../shared/Modal'
import { ClientAccessRequestAction } from '../../enums'
import Loader from '../loader'

const styles = ({ palette }: Theme) =>
  createStyles({
    modalBody: {
      alignItems: 'flex-start',
    },
    modalTitle: {
      paddingBottom: '1.3rem',
      textAlign: 'left',
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
    modalAdditional: {
      fontStyle: 'italic',
      textAlign: 'left',
      fontSize: '1rem',
    },
    modalSearch: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'nowrap',
      width: '20.5rem',
      lineHeight: 1.4,
      fontSize: '1.3rem',
      backgroundColor: `${palette.grey[200]}`,
    },
    searchResults: {
      backgroundColor: '#d7d7d7',
      width: '31rem',
      height: '12rem',
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    searchIcon: {
      color: `${palette.grey[400]}`,
      marginRight: '1rem',
    },
    inputBox: {
      width: '20rem',
      borderStyle: 'none',
      outline: 'none',
      marginLeft: '0.7rem',
      backgroundColor: `${palette.grey[200]}`,
    },
  })

export interface FindClientOwnProps {
  clientDialogTitle?: string
  clientDialogAdditional?: string
  clients?: ClientSearch[]
  displayResults?: boolean
  searchString: string
  clientAccessSearchStatus: string
  setActions: (action: string) => void
  submitClientAccessRequest: (clientId: number, masterId: number) => void
  handleChange: (searchString: string) => void
}

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

const FindClientModal = ({
  clientDialogTitle,
  clientDialogAdditional,
  clients,
  displayResults,
  searchString,
  clientAccessSearchStatus,
  setActions,
  submitClientAccessRequest,
  handleChange,
}: FindClientOwnProps) => {
  const classes = useStyles()
  const clientsToDisplay = displayResults ? clients || [] : []
  const [displayNoResults, setDisplayNoResults] = React.useState(false)
  const [isInputTextModified, setIsInputTextModified] = React.useState(false)

  const addClientClick = async (
    client: any,
    submitClientAccessRequest: any
  ) => {
    await submitClientAccessRequest(client.clientId, client.masterId)
    setActions(ClientAccessRequestAction.Add)
  }

  const onHandleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length > 6 && !isInputTextModified) {
      setIsInputTextModified(true)
    }
    handleChange(e.currentTarget.value.replace(/\D/g, '')) //Only the numbers from the entered value are accepted.
  }

  React.useEffect(() => {
    if (searchString.length > 6 && clientsToDisplay.length === 0) {
      setDisplayNoResults(true)
    } else {
      setDisplayNoResults(false)
    }
  }, [clientsToDisplay.length, isInputTextModified])

  return (
    <Modal
      shown={true}
      onClose={() => setActions(ClientAccessRequestAction.Close)}
    >
      <div id='find-client'>
        <div className={classes.modalBody}>
          <div className={classes.modalTitle}>{clientDialogTitle}</div>
          <div className={classes.modalAdditional}>
            {clientDialogAdditional}
          </div>
          <div className={classes.modalSearch}>
            <input
              type='text'
              maxLength={7}
              className={classes.inputBox}
              value={searchString}
              onChange={e => onHandleSearchChange(e)}
            />
            <FaSearch className={classes.searchIcon} />
          </div>

          <Loader ready={clientAccessSearchStatus !== 'Processing'}>
            {displayNoResults && (
              <div className='client-access-search-box-result'>
                No results found.
              </div>
            )}
          </Loader>

          <div className={classes.searchResults}>
            {clientsToDisplay!.map((client, index) => {
              return (
                <RenderFindClient
                  disabled={
                    client.clientAccessStatus &&
                    client.clientAccessStatus.length > 0
                      ? true
                      : false
                  }
                  key={index}
                  client={client}
                  index={index.toString()}
                  onClick={() =>
                    addClientClick(client, submitClientAccessRequest)
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FindClientModal
