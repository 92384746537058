import {
  EntityMap,
  EtlFile,
  IEngagementQuestionAnswer,
  K1Header,
  QuestionStateSummary,
  TaxIntegrationStatus,
} from '../clientModels'
import { PhaseCode } from '../enums'
import { ensureNumber, isObjectEmpty } from '../guards'
import * as ActivityFactory from '../services/activityFactory'
import { AnswersApi, EngagementsApi, FileGroupsApi } from '../services/api'
import { saveBlob } from '../services/http'
import { perf } from '../services/performance'
import { trackGet, trackSave } from '../services/track'
import { TsaThunkAction } from '../store'
import {
  generateCommentsCount,
  getAllActivities,
  saveActivity,
} from './activityThunks'
import { buildClientAnswer, registerFlagChange } from './answerActionHelpers'
import {
  startLoadAnswersEtlAction,
  copyLastYearAnswersAction,
  documentArchiveBeginAction,
  documentArchiveDoneAction,
  documentArchiveFailAction,
  getEngagementAction,
  getEngagementFinishedLoadingAction,
  getEngagementListAction,
  getLastYearsDataAction,
  setEngagementCriticalAction,
  setEngagementNotCriticalAction,
  submitEngagementAction,
  toggleFavoriteEngagementAction,
  toggleLockEngagementAction,
  updateEngagementLastModifiedAction,
  updateEngagementPhaseAction,
  updateEngagementQuestionStateSummaryAction,
} from './engagementActions'
import { setDefaultEngagementSectionsQuestions } from './engagementSectionsQuestionsThunks'
import { getEngagementTemplateDocument } from './engagementTemplateThunks'
import {
  getEngagementSectionGroups,
  clearEngagementSectionGroups,
} from './engagementSectionGroupsThunks'
import { httpBeginAction, httpEndAction } from './httpActions'
import {
  normalizeEngagement,
  normalizeEngagementArray,
  normalizeLastYearAnswers,
} from './normalization'
import { getPhaseList } from './phaseThunks'
import { runAllEngagementRules } from './rulesThunks'
import { searchActionClear } from './searchActions'
import { EngagementQuestionsState } from '../reducers'

const performanceEngagementSingle = perf('Engagement (Single)', true)

/**
 * Get an engagement and all related data.
 *   - Engagement
 *   - Engagement's Client
 *   - Engagmenet's Answers
 *   - Engagement's FileGroups
 *   - Engagement Template + Sections + Questions (If not already loaded on the store.)
 *   - Engagement Template Rules (If not already loaded on the store.)
 *
 * This action also loads rules into a rules engine and executes the rules for the first time.
 * @param engagementId
 */
export const getEngagement = (
  engagementId: string | number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)
    if (engagementId === 0) {
      return
    }

    performanceEngagementSingle.beginMark(engagementId)

    dispatch(getEngagementAction.request(engagementId))

    // Clear search results because they are specific to an engagement
    dispatch(searchActionClear())

    // Clear any existing engagement section groups
    dispatch(clearEngagementSectionGroups())

    // First get the engagement. We need this to get the engagement template ID.
    // Since getEngagementTemplate will skip the HTTP call if the result is already
    // cached on the store we don't want to get the template in the same call.
    const results = await Promise.all([
      trackGet(EngagementsApi.apiGetEngagement, dispatch, id, engagementId),
      trackGet(AnswersApi.apiGetAnswers, dispatch, id, engagementId),
      dispatch(getAllActivities(engagementId, id)),
      trackGet(FileGroupsApi.apiGetFileGroups, dispatch, id, engagementId),
      trackGet(AnswersApi.apiGetLastYearValues, dispatch, id, engagementId),
    ])

    const engagement = results[0]

    // Get engagement section groups for display on UI
    engagement && dispatch(getEngagementSectionGroups(engagement))

    const engagementTemplateId = engagement.engagementTemplateId

    // Load the engagement template.
    await dispatch(
      getEngagementTemplateDocument(
        engagement.engagementTaxForm,
        engagement.taxYear
      )
    )

    dispatch(
      setDefaultEngagementSectionsQuestions(engagementId, engagementTemplateId)
    )

    const { engagements, clients } = normalizeEngagement(engagement)
    const currentAnswers = results[1]
    const fileGroups = results[3]
    const answersLastYear = normalizeLastYearAnswers(results[4])

    const answers: EntityMap<IEngagementQuestionAnswer> = {}
    for (const currentAnswer of currentAnswers) {
      const answer = buildClientAnswer(currentAnswer)
      const lastYearAnswer = answersLastYear[currentAnswer.questionId]
      if (lastYearAnswer) {
        answer.answerValueLastYear = lastYearAnswer.valueLastYear
      }
      answers[answer.questionId] = answer
    }

    let k1s: K1Header[] = []
    let etlFiles: EntityMap<EtlFile> = {}

    dispatch(
      getEngagementAction.success({
        engagementId,
        engagements,
        answers,
        fileGroups,
        clients,
        k1s,
        etlFiles,
      })
    )
    dispatch(generateCommentsCount(engagementId))

    const template = getState().engagementTemplates[
      engagement.engagementTemplateId
    ]
    const questions = template && template.questions

    if (questions && questions.length > 0) {
      for (const question of questions) {
        registerFlagChange(engagementId, question, 'Active', true)
      }
    }

    await dispatch(runAllEngagementRules(engagementId))

    dispatch(getEngagementFinishedLoadingAction({ engagementId }))
    performanceEngagementSingle.endMark(engagementId)
  } catch (error) {
    dispatch(getEngagementAction.failure(error))
    performanceEngagementSingle.endMark(engagementId)
  }
}

/**
 * Gets just engagement.
 *   - Engagement
 *
 * This action also loads rules into a rules engine and executes the rules for the first time.
 * @param engagementId
 */
export const getSimpleEngagement = (
  engagementId: string | number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)
    if (engagementId === 0) {
      return
    }

    performanceEngagementSingle.beginMark(engagementId)

    dispatch(getEngagementAction.request(engagementId))

    const engagement = await trackGet(
      EngagementsApi.apiGetEngagement,
      dispatch,
      id,
      engagementId
    )

    // Clear any existing engagement section groups
    dispatch(clearEngagementSectionGroups())

    // Get engagement section groups for display on UI
    engagement && dispatch(getEngagementSectionGroups(engagement))

    const { engagements } = normalizeEngagement(engagement)
    const answers = {}
    const fileGroups: any[] = []
    const clients = {}
    const k1s: any[] = []
    const etlFiles = {}

    dispatch(
      getEngagementAction.success({
        engagementId,
        engagements,
        answers,
        fileGroups,
        clients,
        k1s,
        etlFiles,
      })
    )

    dispatch(getEngagementFinishedLoadingAction({ engagementId }))
    performanceEngagementSingle.endMark(engagementId)
  } catch (error) {
    dispatch(getEngagementAction.failure(error))
    performanceEngagementSingle.endMark(engagementId)
  }
}

export const GetEngagementsWatchId = 'engagements-list'

/**
 * Get a list of all engagements. This will need to be filtered in the future.
 */
export const getEngagementList = (
  entityGroupId?: number,
  isClientId?: boolean
): TsaThunkAction => async dispatch => {
  try {
    dispatch(getEngagementListAction.request())

    // get phases
    dispatch(getPhaseList(GetEngagementsWatchId))
    const engagements = await trackGet(
      isClientId
        ? EngagementsApi.apiGetEngagementListByClientId
        : EngagementsApi.apiGetEngagementListByMasterClientId,
      dispatch,
      GetEngagementsWatchId,
      entityGroupId
    )
    const engagementsMap = normalizeEngagementArray(engagements)

    return dispatch(getEngagementListAction.success(engagementsMap as any))
  } catch (error) {
    return dispatch(getEngagementListAction.failure(error))
  }
}

export const toggleFavoriteEngagement = (
  engagementId: number | string
): TsaThunkAction => async dispatch => {
  try {
    dispatch(toggleFavoriteEngagementAction.request({ engagementId }))

    await EngagementsApi.apiToggleEngagementFavorite(engagementId)
    // do not need to do anything with the results of the API call
    // const engagementMap = normalizeEngagement(engagement)
    // const normalizedEngagement =
    //   engagementMap.engagements[ensureNumber(engagementId)]
    // if (normalizedEngagement) {
    //   normalizedEngagement.visible = true
    // }
    // dispatch(
    //   toggleFavoriteEngagementAction.success({
    //     engagement: normalizedEngagement,
    //   })
    // )
  } catch (error) {
    dispatch(toggleFavoriteEngagementAction.failure(error))
  }
}

export const toggleLockEngagement = (
  engagementId: number | string,
  clientId?: number
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    dispatch(toggleLockEngagementAction.request({ engagementId }))

    const engagement = getState().engagements[engagementId]
    if (!engagement) {
      throw new Error('Could not find engagement')
    }

    if (!engagement.isEnabled) {
      const activity = ActivityFactory.CreateEngagementLockActivity(
        engagementId,
        clientId
      )

      dispatch(saveActivity(activity))
    }

    return dispatch(toggleLockEngagementAction.success({ engagementId }))
  } catch (error) {
    return dispatch(toggleLockEngagementAction.failure(error))
  }
}

export const getLastYearsData = (
  engagementId: number | string,
  entityGroupId?: number,
  isClientId?: boolean
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    dispatch(getLastYearsDataAction.request())

    const engagement = getState().engagements[engagementId]
    if (!engagement) {
      throw new Error('Could not find engagement')
    }

    EngagementsApi.oDataGetLastYearsData(engagementId)
      .then(() => {
        // Refresh list on submit.
        dispatch(getEngagementList(entityGroupId, isClientId))
        dispatch(getLastYearsDataAction.success({ engagementId }))
      })
      .catch(exception => dispatch(getLastYearsDataAction.failure(exception)))
  } catch (error) {
    dispatch(getLastYearsDataAction.failure(error))
  }
}

/**
 * Initiates the behavior that copies data from last years answers into current answers.
 * This is currently invoked from both the engagementsList component and within the dashboard for an individual engagement and needs to support both cases
 * If being invoked from engagementsList, do not need to execute client side formatting like when done when invoked elsewhere (i.e. when loading an individual engagement.)

 * @param engagementId
 * @param invokedFromEngagementsList = false
 */
export const copyLastYearAnswers = (
  engagementId: number | string,
  invokedFromEngagementsList = false
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    dispatch(copyLastYearAnswersAction.request())

    // regardless of how invoked, need the engagementId from the store.
    const engagement = getState().engagements[engagementId]
    if (!engagement) {
      throw new Error('Could not find engagement')
    }

    // engagementQuestions is only guaranteed to load an object with values if invoked within the dashboard for an individual engagement.
    const engagementQuestions =
      getState().engagementQuestions[engagementId] ||
      ({} as EngagementQuestionsState)

    // regardless of how invoked, need the API call.
    const currentAnswers = await AnswersApi.apiCopyLastYearAnswers(engagementId)
    const answers: EntityMap<IEngagementQuestionAnswer> = {}

    // regardless of how invoked, execute this for loop.
    for (const currentAnswer of currentAnswers) {
      const answer = buildClientAnswer(currentAnswer)
      // if invoked from engagementsList and not dashboard, don't need to execute following.
      // Also, that engagementQuestions object is only guaranteed to be populated when navigating into a particular engagement. If that object is not populated, no need to execute the following.
      if (!invokedFromEngagementsList && !isObjectEmpty(engagementQuestions)) {
        const prevAnswer = engagementQuestions[currentAnswer.questionId]
        if (engagementQuestions && prevAnswer) {
          answer.answerValueLastYear = prevAnswer.answerValueLastYear ?? null
        }
      }

      answers[answer.questionId] = answer
    }
    dispatch(copyLastYearAnswersAction.success({ engagementId, answers }))

    // if invoked from engagementsList, don't need to run rules.
    if (!invokedFromEngagementsList) {
      await dispatch(runAllEngagementRules(engagementId))
    }
  } catch (error) {
    dispatch(copyLastYearAnswersAction.failure(error))
  }
}

export const submitEngagement = (
  engagementId: number | string,
  entityGroupId?: number
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    dispatch(submitEngagementAction.request())

    const engagement = getState().engagements[engagementId]
    if (!engagement) {
      throw new Error('Could not find engagement')
    }

    EngagementsApi.oDataEngagementSubmit(engagementId)
      .then(() => {
        // Refresh list on submit.
        dispatch(getEngagementList(entityGroupId))
        dispatch(submitEngagementAction.success({ engagementId }))
      })
      .catch(exception => dispatch(submitEngagementAction.failure(exception)))
  } catch (error) {
    dispatch(submitEngagementAction.failure(error))
  }
}

export const updateEngagementQuestionStateSummary = (
  engagementId: number | string,
  engagementSummary: QuestionStateSummary
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    const engagement = getState().engagements[engagementId]
    if (!engagement) {
      throw new Error('Could not find engagement')
    }

    dispatch(
      updateEngagementQuestionStateSummaryAction.success({
        engagementId,
        engagementSummary,
      })
    )

    const updatedEngagement = getState().engagements[engagementId]
    if (!updatedEngagement) {
      throw new Error('Could not find engagement')
    }

    if (
      engagement.successfulQuestions !==
        updatedEngagement.successfulQuestions ||
      engagement.totalQuestions !== updatedEngagement.totalQuestions
    ) {
      await trackSave(
        EngagementsApi.apiSaveEngagement,
        dispatch,
        engagementId.toString(),
        updatedEngagement
      )
    }
  } catch (error) {
    dispatch(updateEngagementQuestionStateSummaryAction.failure(error))
  }
}

export const updateEngagementPhase = (
  engagementId: number | string,
  phase: PhaseCode,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    engagementId = ensureNumber(engagementId)

    const engagement = getState().engagements[engagementId]
    if (!engagement) {
      throw new Error('Could not find engagement')
    }

    dispatch(httpBeginAction({ id: 'update-engagement-phase' }))
    // This action changes the phase of the engagement on the store
    dispatch(
      updateEngagementPhaseAction.request({
        engagementId,
        phase,
      })
    )
    // Save the phase to the DB before loading the engagement.
    await dispatch(saveEngagement(engagementId, id))

    if (!engagement.loaded) {
      // If the engagement is not fully loaded we need load it now so we can run rules and calculate
      // question summary using the new phase. This can happen if we are on the dashboard. We have the
      // engagement record but not the full template, rules, answers, etc.
      await dispatch(getEngagement(engagementId, id))
    }

    // Run all of the rules so that total questions, successful questions, etc are updated before we save.
    await dispatch(runAllEngagementRules(engagementId))

    // This is a temp fix, it saves twice once for phase change rule
    // and the second in saveEngagement thunk, FIX THIS
    dispatch(saveEngagement(engagementId, id))
    dispatch(httpEndAction({ id: 'update-engagement-phase' }))
  } catch (error) {
    dispatch(updateEngagementPhaseAction.failure(error))
  }
}

export const saveEngagement = (
  engagementId: number | string,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  engagementId = ensureNumber(engagementId)

  // Get the updated version of the engagement from the store
  const engagement = getState().engagements[engagementId]
  if (!engagement) {
    throw new Error('Could not find engagement')
  }

  // Save the changes to the engagement.
  const engagements = await trackSave(
    EngagementsApi.apiSaveEngagement,
    dispatch,
    id,
    engagement
  )
  const engagementsMap = normalizeEngagement(engagements).engagements

  engagements.lastUpdatedDate &&
    dispatch(
      updateEngagementLastModifiedAction({
        engagementId,
        lastUpdatedDate: engagements.lastUpdatedDate,
      })
    )

  dispatch(
    updateEngagementPhaseAction.success({
      engagementId: engagement.id,
      engagements: engagementsMap,
    })
  )
}

export const startLoadAnswersEtl = (
  engagementId: number
): TsaThunkAction<Promise<TaxIntegrationStatus | null>> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(startLoadAnswersEtlAction.request())
    const result = await trackSave(
      EngagementsApi.startLoadAnswersEtl,
      dispatch,
      'startLoadAnswers',
      engagementId
    )
    if (!result) {
      dispatch(
        startLoadAnswersEtlAction.failure(
          new Error('Could not start import process')
        )
      )
    }
    dispatch(
      startLoadAnswersEtlAction.success({
        engagementId,
        taxIntegrationStatus: result,
      })
    )
    return result
  } catch (error) {
    dispatch(startLoadAnswersEtlAction.failure(error))
  }
  return null
}

export const setEngagementCritical = (
  engagementId: number,
  questionId: number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    dispatch(setEngagementCriticalAction.request())
    const state = getState()
    const engagement = state.engagements[engagementId]
    const question = state.questions[questionId]
    const user = state.auth.user
    if (!engagement || !question || !user) {
      return dispatch(
        setEngagementCriticalAction.failure(
          new Error('Could not find the engagement, question or user')
        )
      )
    }
    const updatedEngagement = await EngagementsApi.engagementIsCritical(
      engagementId,
      questionId,
      user
    )
    const engagementsMap = normalizeEngagement(updatedEngagement).engagements

    return dispatch(
      setEngagementCriticalAction.success({
        engagementId,
        engagements: engagementsMap,
      })
    )
  } catch (error) {
    return dispatch(setEngagementCriticalAction.failure(error))
  }
}

export const setEngagementNotCritical = (
  engagementId: number,
  id?: string
): TsaThunkAction => async (dispatch, getState) => {
  try {
    dispatch(setEngagementNotCriticalAction.request())
    const state = getState()
    const engagement = state.engagements[engagementId]
    const user = state.auth.user
    if (!engagement || !user) {
      return dispatch(
        setEngagementNotCriticalAction.failure(
          new Error('Could not find the engagement or user')
        )
      )
    }
    const updatedEngagement = await EngagementsApi.oDataEngagementIsNotCritical(
      engagementId,
      user
    )
    const engagementsMap = normalizeEngagement(updatedEngagement).engagements

    return dispatch(
      setEngagementNotCriticalAction.success({
        engagementId,
        engagements: engagementsMap,
      })
    )
  } catch (error) {
    return dispatch(setEngagementNotCriticalAction.failure(error))
  }
}

export const documentArchiveDownload = (
  engagementId: number,
  id?: string
): TsaThunkAction => async dispatch => {
  try {
    dispatch(documentArchiveBeginAction({ engagementId }))
    const response = await trackGet(
      EngagementsApi.apiGetArchivedDocument,
      dispatch,
      id,
      engagementId
    )
    await saveBlob(response)
    return dispatch(documentArchiveDoneAction({ engagementId }))
  } catch (error) {
    return dispatch(documentArchiveFailAction(error, { engagementId }))
  }
}
