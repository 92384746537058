import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { EntityMap, EtlFile, File } from '../../clientModels'
import { Grid } from '../layout'
import Column from '../layout/column'
import VirtualList from '../virtualList'
import DataFileRow from './dataFileRow'
import { SelectedFiles, FilesUploadStatus } from '../../reducers'
import { DefaultSort } from './fileSorts'
import { FileEventApi } from './dataFileManagement'
import { useConfigureImportParams } from '../configureImport/useConfigureImportParams'
import FileListRow from '../fileUploadAdvanced/fileListRow'
import { getK1FileProgress } from '../configureImport/helpers'
import { useSelector } from 'react-redux'
import { AppState } from '../../store'
import { DocumentTitleId, UserPermissions } from '../../enums'
import PermissionContent from '../internal/permissionContent'

export interface DataFileGridProps {
  allFilesSelected: boolean
  disabled: (file: File) => boolean
  etlFiles: EntityMap<EtlFile>
  files: File[]
  handlers: FileEventApi
  selectedFiles: SelectedFiles
  selectAllFiles?: () => void
  uploadStatus: FilesUploadStatus
}

function DataFileGrid({
  allFilesSelected,
  disabled,
  etlFiles,
  files,
  selectedFiles,
  handlers,
  selectAllFiles,
  uploadStatus,
}: DataFileGridProps) {
  const [sort, setSort] = useState<string>('AddedDate')
  const [sortAscending, setSortAscending] = useState(true)
  const k1FileStatuses = useSelector(
    (state: AppState) => state.inputProcess.k1FileStatuses
  )
  const { documentTitleId } = useConfigureImportParams()
  const fileStatus = useSelector(
    (state: AppState) => state.fileUpload.uploadStatus
  )

  const sortedFiles = useMemo(
    function() {
      const sortFunction = DefaultSort[sort]
      let result = files
      if (sortFunction) {
        result = files.sort(sortFunction(sortAscending))
      }
      return result
    },
    [files, sort, sortAscending]
  )

  const filesFound = sortedFiles.length > 0

  function getFileListRow(index: number) {
    const file = sortedFiles[index]
    const etlFile = etlFiles[file.id]
    const etlProgress = getK1FileProgress(file)
    const k1FileStatus = k1FileStatuses[file.id]
    const status = fileStatus[file.name]

    if (!file) return <React.Fragment />
    // NOT K1Document K1UploadDocument documents
    if (
      !(
        documentTitleId === DocumentTitleId.K1Document ||
        documentTitleId === DocumentTitleId.K1UpLoadDocument
      )
    ) {
      return (
        <DataFileRow
          added={moment.utc(file.createdDate, moment.ISO_8601)}
          alt={index % 2 !== 0}
          by={
            file.userProfile
              ? `${file.userProfile.lastName}, ${file.userProfile.firstName}`
              : ''
          }
          disabled={disabled(file)}
          etlFile={etlFile}
          file={file}
          files={files}
          key={`${file.id}-${file.name}-${file.createdDate}`}
          handlers={handlers}
          selected={selectedFiles[file.id]}
          uploadStatus={uploadStatus[file.id]}
        />
      )
    }

    return (
      <FileListRow
        added={moment.utc(file.createdDate, moment.ISO_8601)}
        alt={index % 2 !== 0}
        by={
          file.userProfile
            ? `${file.userProfile.lastName}, ${file.userProfile.firstName}`
            : ''
        }
        disabled={disabled(file)}
        etlProgress={etlProgress}
        file={file}
        files={files}
        handlers={handlers}
        k1FileStatus={k1FileStatus}
        key={`${file.id}-${file.name}-${file.createdDate}`}
        selected={selectedFiles[file.id]}
        status={status ?? file.status}
      />
    )
  }

  function changeSort(sortName: string) {
    if (sort !== sortName) {
      setSort(sortName)
      setSortAscending(true)
    } else {
      setSortAscending(!sortAscending)
    }
  }

  function handleFilenameClick() {
    changeSort('FileName')
  }

  function handleAddedDateClick() {
    changeSort('AddedDate')
  }

  function handleAddedByClick() {
    changeSort('AddedBy')
  }

  function handleStatusClick() {
    changeSort('Status')
  }

  function getArrow(sortName: string) {
    if (sortName !== sort) {
      return
    }
    return (
      <div className='file-sort-arrow'>
        {sortAscending ? (
          <div className='triangle-up' />
        ) : (
          <div className='triangle-down' />
        )}
      </div>
    )
  }

  return (
    <Grid baseClassName='file-list-grid'>
      <div className='file-list-row-header'>
        <Column
          className='file-list-column-name'
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
        >
          <div className='column-flex'>
            {filesFound && (
              <input
                type='checkBox'
                defaultChecked={allFilesSelected}
                onChange={selectAllFiles}
              />
            )}
            <div
              className='column-flex clickable'
              onClick={handleFilenameClick}
            >
              Filename
              {getArrow('FileName')}
            </div>
          </div>
        </Column>
        <Column
          className='file-list-column-by'
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          onClick={handleAddedByClick}
        >
          <div className='column-flex clickable'>
            Added By
            {getArrow('AddedBy')}
          </div>
        </Column>
        <Column
          className='file-list-column-date'
          lg={1}
          xl={1}
          md={1}
          xs={1}
          sm={1}
          onClick={handleAddedDateClick}
        >
          <div className='column-flex clickable'>
            Date Added
            {getArrow('AddedDate')}
          </div>
        </Column>
        <Column
          className='file-list-column-status'
          xs={1}
          sm={1}
          md={1}
          lg={1}
          xl={1}
          onClick={handleStatusClick}
        >
          <div className='column-flex clickable'>
            Status
            {getArrow('Status')}
          </div>
        </Column>
        <Column
          className='file-list-column-buttons'
          xs={2}
          sm={2}
          md={2}
          lg={2}
          xl={2}
        >
          {sortedFiles.length} results
        </Column>
      </div>

      {filesFound ? (
        <React.Fragment>
          <div className='file-list-rows'>
            {/*
              Virtualizing small lists takes longer than rendering as usual.
              Further, virtualizing re-renders rows far more often because they are
              mounting and un-mounting every time. So react cannot diff them.
            */}
            {sortedFiles.length < 100 &&
              sortedFiles.map((file, index) => getFileListRow(index))}
            {sortedFiles.length >= 100 && (
              <VirtualList itemCount={sortedFiles.length}>
                {getFileListRow}
              </VirtualList>
            )}
          </div>
        </React.Fragment>
      ) : (
        <span className='no-files-found'>No files found!</span>
      )}
    </Grid>
  )
}

export default DataFileGrid
