import React from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { EtlFile, File } from '../../clientModels'
import { EtlFileStatus, FileStatus } from '../../enums'
import Icon from '../icon/icon'
import PermissionContent from '../internal/permissionContent'
import { Column } from '../layout'
import { ConfirmDeleteFileDialog } from '../modals'
import { FileEventApi } from './dataFileManagement'
import DataFileStatus from './dataFileStatus'
import { containsFileTag } from './fileSorts'
import { VersionIcon } from './versionIcon'
import { usePermissionManager } from '../../hooks'
import { UserPermissions } from '../../enums'

export interface DataFileRowExternalProps {
  added: moment.Moment
  alt?: boolean
  baseClassName?: string
  by: string
  disabled: boolean
  etlFile?: EtlFile
  file: File
  files: File[]
  handlers: FileEventApi
  selected: boolean
  uploadStatus?: string
}

export const DataFileRow: React.SFC<DataFileRowExternalProps> = ({
  added,
  alt,
  baseClassName,
  by,
  disabled,
  etlFile,
  file,
  files,
  handlers,
  selected,
  uploadStatus,
}) => {
  const is7216 = containsFileTag(file.tags, 'subject-to-7216')
  const isPrivilege = containsFileTag(file.tags, 'subject-to-privilege')

  const addedDatePhrase = (): string => {
    const now = moment()
    return moment.duration(now.diff(added)).asDays() > 2
      ? added.format('MM/DD/YYYY LT')
      : added.fromNow()
  }

  const [showConfirmDelete, displayDeleteConfirmation] = React.useState(false)
  const { hasPermission } = usePermissionManager()

  const subjectTo7216Id = `${file.id}_input_subject_to_7216`
  const subjectTo7216LabelId = `${file.id}_label_subject_to_7216`
  const subjectToPrivilegeId = `${file.id}_input_subject_to_privilege`
  const subjectToPrivilegeLabelId = `${file.id}_label_subject_to_privilege`

  const canEditDataFiles = hasPermission(UserPermissions.DataFilesCanEdit)

  const fileType = file.name.split('.').pop()
  const showConfigure = !!(
    canEditDataFiles &&
    fileType &&
    ['xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xls', 'pdf'].includes(
      fileType.toLowerCase()
    )
  )
  const showVersionIcon = canEditDataFiles && !!file.versionId
  const noDelete: string[] = [
    FileStatus.Uploading,
    EtlFileStatus.AssignEntities,
    EtlFileStatus.AssignTaxCodes,
    EtlFileStatus.SubmissionInProgress,
  ]
  const showDelete = (s: string | undefined) => {
    return s ? !noDelete.includes(s) : true
  }

  const enableDelete =
    handlers.onClickDelete &&
    showDelete(file.status) &&
    showDelete(etlFile?.status) &&
    !disabled

  return (
    <div className={classNames('file-list-row', { alt: alt }, baseClassName)}>
      <Column
        className='file-list-column-name'
        xs={4}
        sm={4}
        md={4}
        lg={4}
        xl={4}
      >
        <label className='clickable'>
          <input
            type='checkbox'
            className='select-box'
            defaultChecked={selected}
            onChange={() =>
              handlers.onClickSelectRow && handlers.onClickSelectRow(file.id)
            }
          />
          <span data-tip={file.name}>{file.name}</span>
        </label>
      </Column>
      <Column
        className='file-list-column-by'
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
      >
        {by && (
          <div className='file-list-column-by-name' data-tip={by}>
            {by}
          </div>
        )}
      </Column>
      <Column
        className='file-list-column-date'
        lg={1}
        xl={1}
        md={1}
        xs={1}
        sm={1}
      >
        <div className='file-list-column-by-date' data-tip={addedDatePhrase()}>
          {addedDatePhrase()}
        </div>
      </Column>
      <Column
        className='file-list-column-uploaded'
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
      >
        <DataFileStatus
          etlFile={etlFile}
          status={uploadStatus || file.status}
          onClickShowError={() =>
            handlers.onClickShowError && handlers.onClickShowError(file.id)
          }
        />
      </Column>
      <Column
        className='file-list-column-buttons'
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
      >
        {
          <Icon
            icon='delete'
            active={enableDelete}
            tooltip='Remove file'
            onClick={() => enableDelete && displayDeleteConfirmation(true)}
          />
        }
        {handlers.onClickUpdate && (
          <Icon
            icon='upload'
            active={!disabled}
            tooltip='Replace file'
            onClick={() =>
              !disabled &&
              handlers.onClickUpdate &&
              handlers.onClickUpdate(file.id)
            }
          />
        )}
        {handlers.onClickDownload && (
          <Icon
            icon='download'
            active={true}
            tooltip='Download file'
            onClick={() =>
              handlers.onClickDownload && handlers.onClickDownload(file.id)
            }
          />
        )}

        {showVersionIcon && (
          <VersionIcon
            files={files}
            parentId={file.parentId}
            active={true}
            onClickDownload={handlers.onClickDownload}
          />
        )}
        {showConfirmDelete && (
          <ConfirmDeleteFileDialog
            title='This will permanently delete selected file(s) from your documents list.'
            info=''
            primaryButtonText='Yes Delete Files'
            secondaryButtonText='No Keep Files'
            onClickPrimary={() =>
              handlers.onClickDelete && handlers.onClickDelete(file.id)
            }
            onClickSecondary={() => displayDeleteConfirmation(false)}
            onClose={() => displayDeleteConfirmation(false)}
          />
        )}
        {showConfigure &&
          etlFile &&
          etlFile.status &&
          [
            EtlFileStatus.ReadyToSubmit as string,
            EtlFileStatus.SubmissionComplete,
            EtlFileStatus.SubmissionError,
          ].includes(etlFile.status) && (
            <Icon
              icon='cch'
              active={true}
              tooltip='Submit to CCH'
              onClick={() =>
                handlers.onClickSubmitToCCH &&
                handlers.onClickSubmitToCCH(file.id)
              }
            />
          )}
        {showConfigure &&
          etlFile &&
          etlFile.status &&
          [EtlFileStatus.SubmissionInProgress as string].includes(
            etlFile.status
          ) && <Icon icon='cch' active={false} tooltip='Submit to CCH' />}
      </Column>
    </div>
  )
}

export default DataFileRow
