import React from 'react'
import { Button, createStyles, Theme } from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import { ClientSearch } from '../../clientModels'
import { padLeadingZeros } from '../../services/formatters/padLeadingZeros'

const styles = ({ palette }: Theme) =>
  createStyles({
    clientContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: '0.7rem',
      paddingTop: '0.3rem',
    },
    buttonContainer: {
      paddingRight: '0.5rem',
    },
    button: {
      paddingRight: '0.5rem',
      padding: '0.3rem 1 rem',
      fontSize: '13px',
    },
    infoContainer: {
      flex: 1,
      fontSize: '0.9rem',
      textAlign: 'left',
    },
    clientName: {
      fontWeight: 'bold',
    },
    deactivated: {
      fontWeight: 'bold',
      color: palette.informational.red,
    },
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

export interface RenderFindClientOwnProps {
  client: ClientSearch
  index: string
  disabled?: boolean
  onClick: (client: any, index: string) => void
}

const RenderFindClient = ({
  client,
  index,
  disabled,
  onClick,
}: RenderFindClientOwnProps) => {
  const classes = useStyles()

  return (
    <div key={index}>
      <div className={classes.clientContainer}>
        <div className={classes.infoContainer}>
          <div>
            <span className={classes.clientName}>{client.name}</span>
          </div>
          <div>
            <span>{padLeadingZeros(client.clientId, client.masterId)}</span>
          </div>
          <div className={classes.deactivated}>
            <span>{client.clientAccessStatus + ''}</span>
          </div>
        </div>

        {!disabled && (
          <div className={classes.buttonContainer}>
            <Button
              variant={'primary'}
              disabled={disabled}
              color='primary'
              onClick={() => onClick(client, index)}
            >
              ADD CLIENT
            </Button>
          </div>
        )}
      </div>
      <hr />
    </div>
  )
}

export default RenderFindClient
