import { ActivitySubType, ActivityType, PhaseCode, RoleCode } from '../../enums'
import { EtlScreen, TemplateType } from '../../clientModels'

// Type definitions for API data models

type YesNo = 'Y' | 'N'
type JsonSerialized = string

export interface Entity {
  id: number

  // TODO: We need to get rid of this indexer. It invalidates all property type checking
  // on anything the extends Entity. It was added to allow for entity['validpropertyname']
  // when we moved to TypeScript 3.4. We need a different way to allow for indexed access.
  [property: string]: any
}

export interface ApiEntityMap<T extends Entity> {
  [id: number]: T | undefined
  [id: string]: T | undefined
}

export interface Activity extends Entity {
  activitySubTypeCode?: ActivitySubType
  activityTypeCode: ActivityType
  carryForwardActivityId?: number
  clientId: number
  createdByInternalUser?: boolean
  createdDate?: string
  engagementId: number
  fileGroupId?: number
  firstName?: string
  isExternal?: boolean
  isLocked?: boolean
  status?: string
  lastName?: string
  mentions?: Mention[]
  parentId?: number
  questionId?: number
  sentDate?: string
  userId: string
  value: string
  propertyBag: string
  isDeleted: boolean
  activityVersion: number
}

export interface Address extends Entity {
  city?: string
  country?: string
  countryId?: number
  state?: string
  stateId?: number
  stateShort?: string
  street1?: string
  street2?: string
  zipCode?: string
}

export interface AsyncOperation extends Entity {
  clientId: number
  createdDateTime: string
  lastUpdatedDateTime: string
  propertyBag: string
  status: string
  userId: string
}

export interface Client extends Entity {
  masterId?: number
  fiscalYearEnd?: number
  name?: string
  nameContact?: string
  isAudit?: YesNo
}

export interface ClientData extends Entity {
  clientId: number
}

export interface ClientConfiguration {
  id: number
}

export interface AnswerMetadata extends Entity {
  answerId: number
  clientId: number
  clientReviewed?: boolean
  concurringReviewed?: boolean
  flagged?: boolean
  preparerReviewed?: boolean
  primaryReviewed?: boolean
  notApplicable?: boolean
  secondaryReviewed?: boolean
  active?: boolean
  answerMetadataVersion: string
}

export interface UpdateFlag {
  flagType: string
  engagementId: number
  questionId: number
  flagValue: boolean
}

export interface CurrentAnswer extends Entity {
  clientId: number
  engagementId: number
  answersMetadataId?: number
  questionId: number
  userId?: string
  user?: UserProfile
  value: string | object | object[] | null
  answerVersion: string
  answersMetadata?: AnswerMetadata
  alsoUpdated?: CurrentAnswer[]
  conflictError?: boolean
  generalError?: boolean
}

export interface DocumentTitle extends Entity {
  id: number
  title: string
  templateFileId: number
  processRule?: number
}

export interface Engagement extends ClientData {
  assignments?: EngagementAssignment[]
  canPreparerContactClient?: boolean
  cchVersion: number
  client?: Client
  clientContactEmail?: string
  clientContactName?: string
  clientContactPhone?: string
  clientId: number
  completionDate?: string
  complexity?: string
  concurringReviewStarted?: boolean
  concurringReviewDone?: boolean
  creationDate?: string
  creator?: string
  criticalError: boolean
  engagementTaxForm?: string
  engagementTemplateId: number
  errorQuestions: number
  fiscalYearEnd?: string
  hasEtlData: boolean
  IsEntityGroup?: boolean
  IsPartOfConsolidatedReturn?: boolean
  lastQuestionId?: number
  lastSectionId?: number
  lastUpdatedBy?: UserProfile
  lastUpdatedById?: number
  lastUpdatedDate?: string
  favorited?: boolean
  name?: string
  needsReviewQuestions: number
  nextYearTemplateAvailable: boolean
  phase?: PhaseCode
  milestones?: EngagementMilestone[]
  preparerReviewStarted?: boolean
  preparerReviewDone?: boolean
  priorYearReturnId?: string
  primaryReviewStarted?: boolean
  primaryReviewDone?: boolean
  questions?: Question[]
  rollForwardDate?: string
  secondaryReviewStarted?: boolean
  secondaryReviewDone?: boolean
  secondaryReviewer?: string
  successfulQuestions: number
  taxIntegrationStatuses?: TaxIntegrationStatus[]
  taxType?: string
  taxYear?: string
  template?: EngagementTemplate
  totalQuestions: number
  type?: string
  unansweredQuestions: number
  usedLastYearsAnswers?: boolean
}

export interface EngagementAssignment extends Entity {
  engagementId: number
  roleCode: RoleCode
  userId: number
  user: UserProfile
}

export interface EngagementMilestone extends Entity {
  engagementId: number
  milestone: string
  dueDate?: string
  completedDate?: string
}

export class EngagementQuestion {
  commentsCount: number = 0
  id: number = 0
  questionId: number = 0
}

export interface EngagementTask extends ClientData {
  client?: Client
  dueDate?: string
  endDate?: string
  engagement?: Engagement
  engagementId: number
  startDate?: string
  status?: string
  section?: Section
  sectionId: number
  sectionRole?: string
  userId: number
}

export interface EngagementTemplate extends Entity {
  cchReturnType: string
  hasCchPhase: boolean
  name: string
  questions: Question[]
  sections: Section[]
  taxYear: number
  resourceType: string
}

export interface EngagementTemplateField extends Entity {
  alignment?: string
  codeListid?: number
  component: string
  dataType: string
  displayOrder: number
  engagementTemplateId: number
  fieldCode: string
  format?: string
  headerWidth?: string
  itemLabels: ItemLabel[]
  maxItems?: number
  maxLength?: number
  maxWidth?: string
  minItems?: number
  minValue?: number
  minWidth?: string
  questionId: number
  required: boolean
  schemaPath?: string
  title?: string
  width?: string
}

export interface EngagementUploadHistory extends Entity {
  createdBy: string
  createdDate: Date
  engagement?: Engagement
  engagementUploadHistoryStatuses: EngagementUploadHistoryStatus[]
  file?: File
  fileId: number
}

export interface EngagementUploadHistoryStatus extends Entity {
  message: string
  status: string
}

export interface EntityEngagement {
  entityId: number
  engagementId: number
  entityShortName: string
  clientId: number
  engagementName: string
  phase?: PhaseCode
  resourceType: string
  taxYear: string
  taxIntegrationStatuses?: TaxIntegrationStatus[]
}

export interface EntityGroup extends Entity {
  id: number
  name: string
  entityEngagements: EntityEngagement[]
}

export interface EntityMapping {
  id: string | null
  name: string | null
  entityId: number
}

export interface File extends Entity {
  [key: string]: any
  clientId?: number
  contentLength?: number
  contents?: Blob
  contentType?: string
  createdDate?: string
  description?: string
  fileGroupId?: number
  name: string
  parentId?: number
  path?: string
  sasUri?: string
  size?: number
  status?: string
  updatedDate?: string
  userProfile?: UserProfile
  versionId?: number
}

export interface FileGroup extends Entity {
  clientId?: number
  createdDate?: string
  documentTitleId?: number
  engagementId?: number
  EngagementTemplateId?: number
  files?: File[]
  notApplicable?: boolean
  questionId?: number
  sectionId?: number
  title?: string
  updatedDate?: string
  userId?: string
}

export interface K1FileStatus extends Entity {
  fileId: number
  status: string
  statusMessage: string
  error: string
  userId: string
  createDate: Date
  updateDate: Date
}
export interface NameNumber {
  name: string
  number: number
}

export interface TaxCodeMapping {
  k1Code: string | null
  k1Description: string | null
  k1Passive: string | null
  k1ActivityCode: string | null
  taxCode: string
}

export interface DataMappingTemplate {
  entityMappings: EntityMapping[]
  taxCodeMappings: TaxCodeMapping[]
}

export type ApiWorksheet = ApiStructureWorksheet | DataMappingTemplate

export interface InputProcessTemplate extends Entity {
  clientId: number
  isLocked: boolean
  isLockedByCurrentUser: boolean
  isUserUploaded: boolean
  templateName: string
  worksheet: ApiWorksheet
  templateType: TemplateType
}

export interface ColumnFilters {
  [id: string]: string[]
}

// used for the "worksheet" property in InputProcessTemplate
// when saving/updating templates
export interface ApiStructureWorksheet {
  formatSetup?: {
    etlType: string
    excludeRows: string
    fileFormat: string
    startingCell: string
    worksheetName: string
  }
  numberOfRows?: number
  overrideExisting?: boolean
  pivotSetup?: {
    columns?: NameNumber[]
    rows?: NameNumber[]
  }
}

// used for API calls that require all information
export interface InputProcessWorksheet extends ApiStructureWorksheet {
  filters?: ColumnFilters
  entityMappings?: EntityMapping[]
  excludeMappedData?: boolean
  taxCodeMappings?: TaxCodeMapping[]
}

export interface ItemLabel extends Entity {
  engagementTemplateId: number
  displayOrder: number
  label: string
}

export interface LastYearAnswer extends Entity {
  engagementId: number
  clientId: number
  questionId: number
  valueLastYear: string | null
}

export interface Mention extends Entity {
  activityId: number
  label: string | null
  value: string | null
  questionId?: number
  resolved: boolean
}

export interface ActivityNotificationMessages extends Entity {
  activityId: number
  completionDate: string
  id: number
  notificationMessageId: number
}

export interface OptionsGroup {
  codeListIds: number[]
  lookupId: number
  options: Array<{ value: string; label: string; group?: boolean }>
}

export interface Partner {
  partnerId: string | null
  name: string
}

export interface Phase extends Entity {
  code: string
  description: string
  displayOrder: number
}

export interface Question extends ClientData, Entity {
  allowNotApplicable: boolean
  clientReviewRequired: boolean
  concurringReviewRequired: boolean
  displayOrder: number
  engagementTemplateId: number
  help?: string
  hint?: string
  number: string
  preparerReviewRequired: boolean
  primaryReviewRequired: boolean
  secondaryReviewRequired: boolean
  sectionId: number
  text: string
  uploadText?: string
  isInternalVisibleOnly: boolean
}

export interface QuestionStateSummary {
  totalQuestions: number
  successfulQuestions: number
  errorQuestions: number
  unansweredQuestions: number
  needsReviewQuestions: number
  lastQuestionId: number
  lastSectionId: number
}

export interface RelatedClient extends Entity {
  addressId: number
  isPerson?: boolean
  name?: string
  nameConsolidated?: string
  nameConsolidated2?: string
  nameContact?: string
  nameFirst?: string
  nameLast?: string
  nameMiddle?: string
  namePrefix?: string
  nameSort?: string
  nameSuffix?: string
}

export interface Role {
  code: RoleCode
  description: string
}

export interface Rule extends Entity {
  id: number
  description?: string
  engagementTemplateId: number
  /** Json Data */
  options: JsonSerialized
}

export interface Section extends Entity {
  description?: string
  displayOrder: number
  engagementTemplateId: number
  number: string
  title: string
}

export interface TaxIntegrationStatus extends Entity {
  createdDate: Date
  engagementId: number
  message: string
  status: string
  type: string
}

export interface ClientUserProfile {
  name: string
  firstName?: string
  lastName?: string
  email?: string
}

export interface UserProfile extends Entity {
  email?: string
  firstName?: string
  lastName?: string
  userId: string
  isInternal: boolean
}

export interface ClientSearch {
  clientAccessStatus: string | undefined
  clientId: number
  masterId?: number
  name: string
}

export interface NotificationTemplateReplacement {
  [token: string]: string | undefined
}

export interface NotificationTemplate {
  bodyHTML?: string
  bodyText?: string
  from?: string
  subject?: string
  to?: string[]
  cc?: string[]
  bcc?: string[]
  replaceableValues?: NotificationTemplateReplacement
}

export interface NotificationTemplateOptions {
  engagementId?: number
  questionIds: number[]
  activityIds?: number[]
}

export interface NotificationSendOptions extends NotificationTemplateOptions {
  to?: string[]
  cc?: string[]
  bcc?: string[]
  subject?: string
  replaceableValues?: NotificationTemplateReplacement
}

export enum SortOrder {
  asc,
  desc,
}

export interface Sort {
  sortBy: number
  sortOrder: SortOrder
}

export interface EtlFile {
  id: number
  fileId: number
  etlTemplateId?: number
  fileSheetDetailsId?: number
  engagementId?: number
  clientId: number
  status?: string
  statusMessage?: string
  error?: string
  step?: string
}

export interface EtlFileProgress {
  // these property names must be capitalized
  // because they are capitalized on the server
  // and the JSON is parsed client-side
  DataMappingTemplateId?: number
  FileId: number
  TemplateId?: number
  Step: EtlScreen
  SheetName: string | null
}

export interface EtlTemplate {
  id: number
  name: string
  description: string
}

export interface K1Progress {
  k1Id: number
  status: string
  engagementId: number
}
